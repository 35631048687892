export const columnDefs = [
  {
    headerName: "id",
    field: "id",
    editable: false
  },
  {
    headerName: "Agent Name",
    field: "name",
    cellEditor: "simpleEditor"
  },
  {
    headerName: "Agent Code",
    field: "code",
    cellEditor: "simpleEditor"
  },
  // {
  //   headerName: "For Username",
  //   field: "username",
  //   cellEditor: "simpleEditor"
  // },
  // {
  //   headerName: "Unique Agents",
  //   field: "unique_agents",
  //   cellEditor: "simpleEditor"
  // },
  // {
  //   headerName: "Unique Clients",
  //   field: "unique_clients",
  //   cellEditor: "simpleEditor"
  // },
  {
    headerName: "",
    colId: "actions",
    cellRenderer: "actionsRenderer",
    editable: false,
    filter: false,
    minWidth: 220
  }
];

export const defaultColDef = {
  editable: true,
  resizable: true,
  filter: true,
  floatingFilter: true,
  suppressKeyboardEvent: params => params.editing
};
