import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "https://mail-fetch.onrender.com/api/all/";

const getAll = () => {
  return axios.get(API_URL, { headers: authHeader() });
};

const getIncorrect = () => {
  return axios.get(API_URL + 'incorrect', { headers: authHeader() });
}

const getIn = () => {
  return axios.get(API_URL + 'in', { headers: authHeader() });
}

const getOut = () => {
  return axios.get(API_URL + 'out', { headers: authHeader() });
}

const getFlag = () => {
  return axios.get(API_URL + 'flag', { headers: authHeader() });
}

const getClient = () => {
  return axios.get(API_URL + 'client', { headers: authHeader() });
}

const update = (id, data) => {
  return axios.put(API_URL + id, data, { headers: authHeader() });
};

const updateAll = () => {
  return axios.post(API_URL + 'update', { headers: authHeader() });
}

const deleteOne = (identifier) => {
  console.log(identifier)
  return axios.post(API_URL, identifier, { headers: authHeader() });
};

const agentService = {
    getAll,
    getIncorrect,
    getIn,
    getOut,
    getFlag,
    getClient,
    update,
    updateAll,
    deleteOne
};

export default agentService