import React, { useState } from "react";
import { Icon, IconButton, Typography } from "@material-ui/core";

const PaginationStatusBar = ({ api }) => {
  const [pageSize, setPageSize] = useState(api.paginationGetPageSize());
  const [currentPage, setCurrentPage] = useState(
    api.paginationGetCurrentPage()
  );
  const [totalPage, setTotalPage] = useState(api.paginationGetTotalPages());
  const [rowCount, setRowCount] = useState(api.paginationGetRowCount());
  const [previousDisable, setPreviousDisable] = useState(true);
  const [previousColor, setPreviousColor] = useState("disabled");
  const [nextDisable, setNextDisable] = useState(false);
  const [nextColor, setNextColor] = useState("primary");

  api.addEventListener("paginationChanged", (params) => {
    setPageSize(params.api.paginationGetPageSize());
    setCurrentPage(params.api.paginationGetCurrentPage());
    setTotalPage(params.api.paginationGetTotalPages());
    setRowCount(params.api.paginationGetRowCount());

    handlePagination();
  });

  const handlePagination = () => {
    if (api.paginationGetCurrentPage() === 0) {
      setPreviousDisable(true);
      setPreviousColor("disabled");
    } else {
      setPreviousDisable(false);
      setPreviousColor("primary");
    }

    if (api.paginationGetCurrentPage() + 1 === api.paginationGetTotalPages()) {
      setNextDisable(true);
      setNextColor("disabled");
    } else {
      setNextDisable(false);
      setNextColor("primary");
    }
  };

  return (
    <div className="flex item-center mr-0" style={{ height: "42px" }}>
      <div className="flex  items-center">
        <Typography className="mr-12">
          {currentPage * pageSize + 1} to{" "}
          {(currentPage + 1) * pageSize > rowCount
            ? rowCount
            : (currentPage + 1) * pageSize}{" "}
          of {rowCount}
        </Typography>

        <IconButton
          size="small"
          disabled={previousDisable}
          onClick={() => api.paginationGoToFirstPage()}
        >
          <Icon color={previousColor}>first_page</Icon>
        </IconButton>

        <IconButton
          size="small"
          disabled={previousDisable}
          onClick={() => api.paginationGoToPreviousPage()}
        >
          <Icon color={previousColor}>chevron_left</Icon>
        </IconButton>

        <Typography className="mx-8">
          Page {currentPage + 1} of {totalPage}
        </Typography>

        <IconButton
          size="small"
          disabled={nextDisable}
          onClick={() => api.paginationGoToNextPage()}
        >
          <Icon color={nextColor}>chevron_right</Icon>
        </IconButton>

        <IconButton
          size="small"
          disabled={nextDisable}
          onClick={() => api.paginationGoToLastPage()}
        >
          <Icon color={nextColor}>last_page</Icon>
        </IconButton>
      </div>
    </div>
  );
};

export default PaginationStatusBar;
