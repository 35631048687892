export const columnDefs = [
  {
    headerName: "TimeStamp",
    field: "timestamp",
    cellEditor: "simpleEditor"
  },
  // {
  //   field: "subject",
  //   cellEditor: "simpleEditor"
  // },
  {
    field: "type",
    cellEditor: "simpleEditor"
  },
  // {
  //   headerName: "From (clients)",
  //   field: "from",
  //   cellEditor: "simpleEditor"
  // },
  // {
  //   headerName: "To (store)",
  //   field: "to",
  //   cellEditor: "simpleEditor"
  // },
  // {
  //   field: "amount",
  //   cellEditor: "simpleEditor"
  // },
  {
    headerName: "For (Username)",
    field: "username",
    cellEditor: "simpleEditor"
  },
  {
    field: "agent",
    cellEditor: "simpleEditor"
  },
  {
    headerName: "Payment from (Clents tag)",
    field: "payment_from",
    cellEditor: "simpleEditor"
  },
  {
    headerName: "Payment to (Clients tag)",
    field: "payment_to",
    cellEditor: "simpleEditor"
  },
  // {
  //   field: "source",
  //   cellEditor: "simpleEditor"
  // },
  // {
  //   field: "destination",
  //   cellEditor: "simpleEditor"
  // },
  // {
  //   headerName: "Client ID",
  //   field: "client_id",
  //   cellEditor: "simpleEditor"
  // },
  // {
  //   headerName: "Bitcoin Amount",
  //   field: "bitcoin_amount",
  //   cellEditor: "simpleEditor"
  // },
  // {
  //   headerName: "Account Email",
  //   field: "account_email",
  //   cellEditor: "simpleEditor"
  // },
  // {
  //   headerName: "BTC Exchange Rate",
  //   field: "btc_exchange_rate",
  //   cellEditor: "simpleEditor"
  // },
  // {
  //   headerName: "Total Purchase Amount",
  //   field: "total_purchase_amount",
  //   cellEditor: "simpleEditor"
  // },
  // {
  //   field: "fee",
  //   cellEditor: "simpleEditor"
  // },
  {
    field: "identifier",
    cellEditor: "simpleEditor"
  },
  {
    headerName: "",
    colId: "actions",
    cellRenderer: "actionsRenderer",
    editable: false,
    filter: false,
    minWidth: 220
  }
];

export const defaultColDef = {
  editable: true,
  resizable: true,
  sortable: true,
  filter: true,
  floatingFilter: true,
  suppressKeyboardEvent: params => params.editing
};
