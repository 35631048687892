import React, { useState } from "react";

import { Navigate } from 'react-router-dom';
import AgentService from "../../services/agent.service";
import { useSelector } from "react-redux";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { columnDefs, defaultColDef } from "./columns";
import GridComponents from "./tablecomponents";

import "./Agent.css";

const Agent = () => {
    const { user: currentUser } = useSelector((state) => state.auth);

    if (!currentUser) {
        return <Navigate to="/login" />;
    }

    const [gridApi, setGridApi] = useState(null);
    const [columnApi, setColumnApi] = useState(null);

    const [rowData, setRowData] = useState(null);

    const frameworkComponents = {
        simpleEditor: GridComponents.SimpleEditor,
        actionsRenderer: GridComponents.ActionsRenderer,
        addRowStatusBar: GridComponents.AddRowStatusBar
    };

    function onGridReady(params) {
        setGridApi(params.api);
        setColumnApi(params.columnApi);

        AgentService.getAll()
            .then(res => res.data)
            .then(data => {
                setRowData(data.slice(0, 1000));
            });
        params.api.sizeColumnsToFit();
    }

    return (
        <div className="my-app">
            <div
                id="myGrid"
                style={{ height: "100%", width: "100%" }}
                className="ag-theme-alpine"
            >
                <AgGridReact
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    rowData={rowData}
                    getRowNodeId={data => data.id}
                    onGridReady={onGridReady}
                    frameworkComponents={frameworkComponents}
                    editType="fullRow"
                    suppressClickEdit
                    statusBar={{
                        statusPanels: [{ statusPanel: "addRowStatusBar" }]
                    }}
                />
            </div>
        </div>
    );
};

export default Agent;
