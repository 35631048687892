import React from "react";
import { Icon, IconButton } from "@material-ui/core";

const RefreshStatusBar = ({ api }) => {
  return (
    <div className="flex item-center" style={{ height: "42px" }}>
      <div className="flex  items-center">
        <IconButton size="small" onClick={() => api.purgeServerSideCache()}>
          <Icon>refresh</Icon>
        </IconButton>
        <IconButton size="small" onClick={() => api.setFilterModel(null)}>
          <Icon>flash_off</Icon>
        </IconButton>
      </div>
    </div>
  );
};

export default RefreshStatusBar;
