import React, { useState } from "react";

import { Navigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import DataService from "../../services/data.service";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import PaginationStatusBar from "./PaginationStatusBar";
import RefreshStatusBar from "./RefreshStatusBar";

const Out = () => {
    const { user: currentUser } = useSelector((state) => state.auth);

    if (!currentUser) {
        return <Navigate to="/login" />;
    }

    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);

    const [rowData, setRowData] = useState([]);

    function onGridReady(params) {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);

        DataService.getOut()
            .then(res => res.data)
            .then(data => {
                setRowData(data);
            });
    }

    const onFirstDataRendered = params => {
        const allColumnIds = [];
        params.columnApi.getAllColumns().forEach(column => {
            allColumnIds.push(column.getId());
        });
        params.columnApi.autoSizeColumns(allColumnIds, false);
    };

    var filterParams = {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          var dateAsString = cellValue;
          if (dateAsString == null) return -1;
          var dateParts = dateAsString.split('T')[0].split('-');
          var cellDate = new Date(
            Number(dateParts[0]),
            Number(dateParts[1]) - 1,
            Number(dateParts[2])
          );
      
          if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
          }
      
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          }
      
          if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
          return 0;
        },
        minValidYear: 2000,
        maxValidYear: 2099,
        inRangeFloatingFilterDateFormat: 'DD MMM YYYY',
      };

    return (
        <div style={{ width: "100%", height: "92vh" }}>
            <div
                id="myGrid"
                style={{
                    height: "100%",
                    width: "100%"
                }}
                className="ag-theme-alpine"
            >
                <AgGridReact
                    defaultColDef={{
                        editable: false,
                        enableRowGroup: false,
                        enablePivot: true,
                        enableValue: true,
                        sortable: true,
                        resizable: true,
                        filter: true,
                        flex: 1,
                        minWidth: 100
                    }}
                    suppressRowClickSelection={true}
                    debug={true}
                    rowSelection={"multiple"}
                    pivotPanelShow={"always"}
                    enableRangeSelection={true}
                    pagination={true}
                    onGridReady={onGridReady}
                    rowData={rowData}
                    suppressPaginationPanel={true}
                    frameworkComponents={{
                        RefreshStatusBar,
                        PaginationStatusBar
                    }}
                    statusBar={{
                        statusPanels: [
                            { statusPanel: "RefreshStatusBar", align: "left" },
                            { statusPanel: "agAggregationComponent", align: "left" },
                            { statusPanel: "PaginationStatusBar" }
                        ]
                    }}
                    onFirstDataRendered={onFirstDataRendered}
                >
                    <AgGridColumn
                        headerName="TimeStamp"
                        field="timestamp"
                        filter="agDateColumnFilter"
                        filterParams={filterParams}
                    ></AgGridColumn>
                    <AgGridColumn field="subject"></AgGridColumn>
                    <AgGridColumn field="type"></AgGridColumn>
                    <AgGridColumn headerName="From (clients)" field="from"></AgGridColumn>
                    <AgGridColumn headerName="To (store)" field="to"></AgGridColumn>
                    <AgGridColumn field="amount"></AgGridColumn>
                    <AgGridColumn headerName="For (Username)" field="username"></AgGridColumn>
                    <AgGridColumn field="agent"></AgGridColumn>
                    <AgGridColumn headerName="Payment to (Clients tag)" field="payment_to"></AgGridColumn>
                    <AgGridColumn headerName="Client ID" field="client_id"></AgGridColumn>
                    <AgGridColumn headerName="Account Email" field="account_email"></AgGridColumn>
                    <AgGridColumn field="fee"></AgGridColumn>
                    <AgGridColumn field="identifier"></AgGridColumn>
                </AgGridReact>
            </div>
        </div>
    );
};

export default Out;
