import React, { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';

const Home = () => {

  return (
    <div className="container">
      <header className="jumbotron">
        <h1>WELCOME TO Mail/CashApp</h1>
        <NavLink to="/all" className="btn btn-warning">Get Started</NavLink>
      </header>
    </div>
  );
};

export default Home;
