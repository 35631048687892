import React, { useState, useEffect, useRef } from 'react';
import AgentService from "../../../services/agent.service";

const useComponentWillMount = func => {
    const willMount = useRef(true);

    if (willMount.current) {
        func();
    }

    willMount.current = false;
};

export default (props) => {
    let [editing, setEditing] = useState(false);
    let [disabled, setDisabled] = useState(false);

    // custom hook
    useComponentWillMount(() => {
        let editingCells = props.api.getEditingCells();
        if (editingCells.length !== 0) {
            setDisabled(true);
        }
    })

    useEffect(() => {
        props.api.addEventListener('rowEditingStarted', onRowEditingStarted);
        props.api.addEventListener('rowEditingStopped', onRowEditingStopped);

        return () => {
            props.api.removeEventListener('rowEditingStarted', onRowEditingStarted);
            props.api.removeEventListener('rowEditingStopped', onRowEditingStopped);
        };
    }, []);

    function onRowEditingStarted(params) {
        if (props.node === params.node) {
            setEditing(true);
        } else {
            setDisabled(true);
        }
    };

    function onRowEditingStopped(params) {
        if (props.node === params.node) {
            if (isEmptyRow(params.data)) {
                deleteRow(true);
            } else {
                setEditing(false);
            }
        } else {
            setDisabled(false);
        }
    }

    function startEditing() {
        props.api.startEditingCell({
            rowIndex: props.rowIndex,
            colKey: props.column.colId
        });
    }

    function stopEditing(bool) {
        props.api.stopEditing(bool);
    }

    function updateRow() {
        stopEditing(false)
        if (props.data.name === undefined && props.data.name === undefined) return;
        if (props.data.id) {
            let data = {
                name: props.data.name,
                code: props.data.code,
                username: props.data.username,
                unique_agents: props.data.unique_agents,
                unique_clients: props.data.unique_clients
            }
            AgentService.update(props.data.id, data)
                .then(result => (console.log(result.status)))
        } else {
            let data = {
                name: props.data.name,
                code: props.data.code,
                username: props.data.username,
                unique_agents: props.data.unique_agents,
                unique_clients: props.data.unique_clients
            }
            AgentService.create(data)
                .then(result => (console.log(result.status)))
                .then(() => window.location.reload(false))
        }
    }

    function deleteRow(force = false) {
        let data = props.data;
        let confirm = true;
        if (!force) {
            confirm = window.confirm(`are you sure you want to delete this row: ${JSON.stringify(data)})`)
            if (confirm) {
                props.api.updateRowData({ remove: [data] });
                props.api.refreshCells({ force: true });
                AgentService.deleteOne(props.data.id)
                    .then(result => (console.log(result.status)))
            }
        } else {
            props.api.updateRowData({ remove: [data] });
            props.api.refreshCells({ force: true });            
        }
    };

    function isEmptyRow(data) {
        let dataCopy = { ...data };
        delete dataCopy.id;
        return !Object.values(dataCopy).some(value => value);
    }

    return (
        <div>
            {editing
                ? <>
                    <button
                        color="primary"
                        variant="contained"
                        onClick={() => updateRow()}
                        disabled={disabled}>Update</button>
                    <button
                        color="secondary"
                        variant="contained"
                        onClick={() => stopEditing(true)}
                        disabled={disabled}>Cancel</button>
                </>
                : <>
                    <button
                        color="primary"
                        variant="outlined"
                        onClick={startEditing}
                        disabled={disabled}>Edit</button>
                    <button
                        color="secondary"
                        variant="outlined"
                        onClick={() => deleteRow()}
                        disabled={disabled}>Delete</button>
                </>
            }
        </div>
    )
}
