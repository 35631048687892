import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Login from "./components/Login";
import Register from "./components/Register";
import Home from "./components/Home";
import Profile from "./components/Profile";
import BoardUser from "./components/BoardUser";
import BoardAdmin from "./components/BoardAdmin";
import Agent from "./components/Agent/Agent";

import { logout } from "./slices/auth";

import EventBus from "./common/EventBus";
import Main from "./components/Main/Main";
import Incorrect from "./components/Incorrect/Incorrect";
import In from "./components/Main/In";
import Out from "./components/Main/Out";
import Flag from "./components/Main/Flag";
import Client from "./components/Main/Client";

const App = () => {
  const [showAdminBoard, setShowAdminBoard] = useState(false);

  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      setShowAdminBoard(currentUser.roles.includes("ROLE_ADMIN"));
    } else {
      setShowAdminBoard(false);
    }

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [currentUser, logOut]);

  return (
    <Router>
      <div>
        <nav className="navbar navbar-expand navbar-dark bg-dark">
          <Link to={"/"} className="navbar-brand">
            CashApp
          </Link>
          <div className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link to={"/home"} className="nav-link">
                Home
              </Link>
            </li>

            <li className="nav-item">
              <Link to={"/all"} className="nav-link">
                Main
              </Link>
            </li>

            <li className="nav-item">
              <Link to={"/incorrect"} className="nav-link">
                Incorrect
              </Link>
            </li>

            <li className="nav-item">
              <Link to={"/in"} className="nav-link">
                In
              </Link>
            </li>

            <li className="nav-item">
              <Link to={"/out"} className="nav-link">
                Out
              </Link>
            </li>

            <li className="nav-item">
              <Link to={"/agent"} className="nav-link">
                Agent
              </Link>
            </li>

            <li className="nav-item">
              <Link to={"/flag"} className="nav-link">
                Flag
              </Link>
            </li>

            <li className="nav-item">
              <Link to={"/client"} className="nav-link">
                Client Data
              </Link>
            </li>

            {showAdminBoard && (
              <li className="nav-item">
                <Link to={"/admin"} className="nav-link">
                  Admin Board
                </Link>
              </li>
            )}
          </div>

          {currentUser ? (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/profile"} className="nav-link">
                  {currentUser.username}
                </Link>
              </li>
              <li className="nav-item">
                <a href="/login" className="nav-link" onClick={logOut}>
                  LogOut
                </a>
              </li>
            </div>
          ) : (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/login"} className="nav-link">
                  Login
                </Link>
              </li>

              <li className="nav-item">
                <Link to={"/register"} className="nav-link">
                  Sign Up
                </Link>
              </li>
            </div>
          )}
        </nav>

        <div className="mt-3">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/user" element={<BoardUser />} />
            <Route path="/admin" element={<BoardAdmin />} />
            <Route path="/agent" element={<Agent />} />
            <Route path="/all" element={<Main />} />
            <Route path="/incorrect" element={<Incorrect />} />
            <Route path="/in" element={<In />} />
            <Route path="/out" element={<Out />} />
            <Route path="/flag" element={<Flag />} />
            <Route path="/client" element={<Client />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
