import React, { useState } from "react";

import { Navigate } from 'react-router-dom';
import DataService from "../../services/data.service";
import { useSelector } from "react-redux";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { columnDefs, defaultColDef } from "./columns";
import GridComponents from "./tablecomponents";

import "./style.css";

const Incorrect = () => {
    const { user: currentUser } = useSelector((state) => state.auth);

    if (!currentUser) {
        return <Navigate to="/login" />;
    }

    const [gridApi, setGridApi] = useState(null);
    const [columnApi, setColumnApi] = useState(null);

    const [rowData, setRowData] = useState(null);

    const frameworkComponents = {
        simpleEditor: GridComponents.SimpleEditor,
        actionsRenderer: GridComponents.ActionsRenderer
    };

    function onGridReady(params) {
        setGridApi(params.api);
        setColumnApi(params.columnApi);

        DataService.getIncorrect()
            .then(res => res.data)
            .then(data => {
                setRowData(data);
            });
        params.api.sizeColumnsToFit();
    }

    const onFirstDataRendered = params => {
        const allColumnIds = [];
        params.columnApi.getAllColumns().forEach(column => {
            allColumnIds.push(column.getId());
        });
        params.columnApi.autoSizeColumns(allColumnIds, false);
    };

    return (
        <div className="my-app">
            <div
                id="myGrid"
                style={{ height: "100%", width: "100%" }}
                className="ag-theme-alpine"
            >
                <AgGridReact
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    rowData={rowData}
                    getRowNodeId={data => data.identifier}
                    onGridReady={onGridReady}
                    frameworkComponents={frameworkComponents}
                    editType="fullRow"
                    suppressClickEdit
                    onFirstDataRendered={onFirstDataRendered}
                />
            </div>
        </div>
    );
};

export default Incorrect;
