import React, { useState, useEffect, useRef } from 'react';
import DataService from "../../../services/data.service";

const useComponentWillMount = func => {
    const willMount = useRef(true);

    if (willMount.current) {
        func();
    }

    willMount.current = false;
};

export default (props) => {
    let [editing, setEditing] = useState(false);
    let [disabled, setDisabled] = useState(false);

    // custom hook
    useComponentWillMount(() => {
        let editingCells = props.api.getEditingCells();
        if (editingCells.length !== 0) {
            setDisabled(true);
        }
    })

    useEffect(() => {
        props.api.addEventListener('rowEditingStarted', onRowEditingStarted);
        props.api.addEventListener('rowEditingStopped', onRowEditingStopped);

        return () => {
            props.api.removeEventListener('rowEditingStarted', onRowEditingStarted);
            props.api.removeEventListener('rowEditingStopped', onRowEditingStopped);
        };
    }, []);

    function onRowEditingStarted(params) {
        if (props.node === params.node) {
            setEditing(true);
        } else {
            setDisabled(true);
        }
    };

    function onRowEditingStopped(params) {
        if (props.node === params.node) {
            if (isEmptyRow(params.data)) {
                deleteRow(true);
            } else {
                setEditing(false);
            }
        } else {
            setDisabled(false);
        }
    }

    function startEditing() {
        props.api.startEditingCell({
            rowIndex: props.rowIndex,
            colKey: props.column.colId
        });
    }

    function stopEditing(bool) {
        props.api.stopEditing(bool);
    }

    function updateRow() {
        stopEditing(false)
        let data = {
            timestamp: props.data.timestamp,
            subject: props.data.subject,
            type: props.data.type,
            from: props.data.from,
            to: props.data.to,
            amount: props.data.amount,
            username: props.data.username,
            agent: props.data.agent,
            payment_from: props.data.payment_from,
            payment_to: props.data.payment_to,
            source: props.data.source,
            destination: props.data.destination,
            client_id: props.data.client_id,
            bitcoin_amount: props.data.bitcoin_amount,
            account_email: props.data.account_email,
            btc_exchange_rate: props.data.btc_exchange_rate,
            total_purchase_amount: props.data.total_purchase_amount,
            fee: props.data.fee,
            identifier: props.data.identifier
        }
        DataService.update(props.data.identifier, data)
            .then(result => (console.log(result.status)))
    }

    function deleteRow(force = false) {
        let data = props.data;
        let confirm = true;
        if (!force) {
            confirm = window.confirm(`are you sure you want to delete this row: ${JSON.stringify(data)})`)
        }
        if (confirm) {
            props.api.updateRowData({ remove: [data] });
            props.api.refreshCells({ force: true });
            DataService.deleteOne({identifier: props.data.identifier})
                .then(result => (console.log(result.status)))
        }
    };

    function isEmptyRow(data) {
        let dataCopy = { ...data };
        delete dataCopy.id;
        return !Object.values(dataCopy).some(value => value);
    }

    return (
        <div>
            {editing
                ? <>
                    <button
                        color="primary"
                        variant="contained"
                        onClick={() => updateRow()}
                        disabled={disabled}>Update</button>
                    <button
                        color="secondary"
                        variant="contained"
                        onClick={() => stopEditing(true)}
                        disabled={disabled}>Cancel</button>
                </>
                : <>
                    <button
                        color="primary"
                        variant="outlined"
                        onClick={startEditing}
                        disabled={disabled}>Edit</button>
                    <button
                        color="secondary"
                        variant="outlined"
                        onClick={() => deleteRow()}
                        disabled={disabled}>Delete</button>
                </>
            }
        </div>
    )
}
